@import '../../../assets/gides-typography.module';
.navigideContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100vw;  
  // max-height: calc(100vh - 290px);
  // padding: 0 5px 0 5px;
  @extend %inputRange;
  max-height: calc(100vh - 221px);
  &.author {
    max-height: calc(100vh - 245px);
  }
}
.navigideMoveTo {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 128px;
  min-height: 128px;
  width: 100%;  
  max-width: 100vw;
  padding: 12px 18px 10px 18px;
  background-color: var(--WHITES-NORMAL-1000);
  .moveToDetails {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 19px;
    @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
    .slideNumberDetails {
      &.hideSlideNumbers {
        display: none;
      }
      .numberOfSlide {
        margin-right: 3px;
      }
      display: flex;
      .number {
        color: var(--COLOR-PRIMARY-600);
      }
    }
  }
}
.navigideSliderContainer {
  width: 100%;
  margin-top: 12px;
  border-bottom: solid .5px var(--COLOR-SECONDARY-200);
}
.sliderTicks {
  height: 3px;
  position: absolute;
  margin-top: 11px;
  background: repeating-linear-gradient(to right, transparent, transparent 17px, #dedede 10px, #dedede 20px);
}
.navigideTOC {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  padding-bottom: 11px;
}
.navigideHeader {
  display: flex;
  align-items: center;;
  height: 50px;
  border-radius: 3px;
  width: 100%;
  padding-right: 5px;  
  @extend .subtitle2IbmSemiBold;
  border-bottom: solid 1px var(--COLOR-SECONDARY-200);
  .navigideHeaderTitle {  
    width: 250px;
    color: var(--COLOR-SECONDARY-500);
    &.hideSlideNumbers {
      display: block;
      width: 100%;
      margin-right: 10px;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    cursor: pointer;
  }
  &.tocLevel0 {
    background-color: rgba(0, 0, 0, 0.03);
  }

  &.tocLevel1 {
    background-color: var(--LINK-20);
    color: var(--LINK-100);
    height: 50px;
    padding-left: 16px;
    .navigideHeaderTitle {
      color: var(--LINK-100);
    }
  }

  &.tocLevel2 {
    padding-left: 32px;
  }

  &.tocLevel3 {
    padding-left: 48px;
  }

  &.tocLevel4 {
    padding-left: 64px;
  }

  &.tocLevel5 {
    padding-left: 70px;
  }
}
.navigideHeaderText {  
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-right: 5px;
  margin-left: 5px;
  .slideRange {
    @extend .TEXTSUBTITLEblackmedium-emphasisright;
  }
  .hideSlideNumbers {
    display: none;
  }
  &.tocLevel0,
  &.tocLevel1 {
    @extend .TEXTH4blackmedium-emphasisleft;
    .slideRange {
    @extend .TEXTSUBTITLEblackhigh-emphasisright;
    }
  }
}

