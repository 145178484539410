@use '../../typography';
@use '../../../assets/gides-typography.module';
.gideTitleSlideContainer {
  position: relative;
  display: flex;
  flex: 1 1;
  flex-direction: column; 
  &.placeHolderContainer {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
    height: 120px;
    background-color: var(--WHITES-NORMAL-1000) !important;
    max-width: 706px;
    width: 100%;  
    background-size: contain;  
    align-items: flex-start;
  }
  .noTitleFormatContianer {
    display: flex;
    align-items: center;
    height: 48px;
    border-left: var(--COLOR-BLUE-100) solid;
    background-color: var(--COLOR-BLUE-20) !important;
    .message {
      height: 20px;
      margin-left: 20px;
      @extend .TEXTSUBTITLEblackmedium-emphasisleft;
      .bold {
        @extend .TEXTSUBTITLEblackhigh-emphasisleft;
      }
    }
  }
  .giveTheGideATitle,
  .gideTitle {
    display: flex;
    padding-top: 4px;
    margin-left: 24px;
    text-align: left;
    word-break: break-word;
    @extend .userEntryTiBoing;
    color: rgba(0, 0, 0, 0.7);
    &.missing {
      @extend .TEXTTIblackboldlow-emphasisleft;
    }
  }
  .gideTitleBlogHeader {
    display: flex;
    flex-direction: column;
    padding: 18px 18px 0 18px;
    .gideTitle {
      margin: 0px;
      padding: 0px;
    }
    .gideDescription {
      @extend .userEntryBodyBoing;
      padding: 12px 0px 12px 0px;
    }
    .titleSlideTagContainer {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      @media (max-width: 520px) {
        flex-wrap: wrap;    
      }
      .tagListHashTagContainer {
        max-width: calc(100% - 275px);
        &.noGeoTags {
          max-width: 100% !important;
        }
        @media (max-width: 520px) {
          max-width: 100%;
        }
        .tagListHashTags {
          color: var(--LINK-100);
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .gideTitleBlogFooter {
    margin-left: 18px;
    margin-right: 18px;
    margin-top: 14px;
    display: flex;
    align-items: center;
    height: 82px;
    justify-content: space-between;
    border-top: solid 1px var(--COLOR-SECONDARY-100);
    border-bottom: solid 1px var(--COLOR-SECONDARY-100);
    @media (max-width: 520px) {
      border: none !important;
      margin: 0px;
    }
  }
  .gideMetadata {
    @extend .subtitle2IbmRegular;    
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: var(--COLOR-SECONDARY-500);
  }

  .giveTheGideATitle {
    width: 318px;
  }
  .gideTitleImage {
    width: 100%;
    --aspect-ratio-w: 3;
    --aspect-ratio-h: 2;
    img {
      object-fit: cover;
    }
  }
  .updateTitleButton {
    position: absolute;
    left: 200px;
    bottom: 21px;
  }
  
}
// TODO: Maybe move this out to its on component file with the component also
.tagContainer {
  display: flex;
  border-radius: 60px 0px 0px 60px;
  align-items: center;
  .iconContainer {
    border-radius: 50%;
    min-width: 28px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tagTitle {
    @extend .subtitle2IbmRegular;
    padding-left: 12px;
    padding-right: 5px;
    white-space: nowrap;
  }
}
