.videoViewOuterContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  .videoViewVideoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .sourceVideo {
      width: 100%;
    }
    div.urlIFrameBasedVideo {
        padding:56.25% 0 0 0;
        position: relative;
        width: 100%;
    }
    iframe.video {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
    }
    .video {
      &:focus { outline: none; }
      width: 100%;
    }
    .videoViewCaptionContainer {
      // height: 25px;
      border-radius: 2px;
      // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
      background-color: #ffffff;
      display: flex;
      flex-wrap: wrap;
      padding: 3px 9px 9px 9px;
      align-items: flex-start;
      width: 100%;
      flex-direction: column;
      .customAudioPlayer {
        width: 100%;
      }
      overflow-wrap: break-word;
      word-break: break-word;
    }
  }
  .videoViewerControls {
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    height: 60px;
    display: flex;
    align-items: center;
  }
  &.hasEnsuingItem {
    margin-bottom: 4px;
  }
  &.containVideo {
    .videoViewVideoContainer {
      video, iframe {
        max-height: calc(100vh - 235px);
        object-fit: contain;
        max-width: 100%;
      }
    }
  }
}
