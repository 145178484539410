@font-face {
  font-family: HILVCC+Boing-Semibold;
  src: url('fonts/Boing-Medium.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

// TODO: Get the correct font files
@font-face {
  font-family: HILVCC+Boing-Light;
  src: url('fonts/Boing-Light.ttf') format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: IBMPlexSans;
  src: url('fonts/IBMPlexSans-Regular.ttf') format("ttf");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: IBMPlexSans-SemiBold;
  src: url('fonts/IBMPlexSans-SemiBold.ttf') format("ttf");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

.userEntryBodyBoing {
  font-family: HILVCC+Boing-Light;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.userEntryTiBoing {
  font-family: HILVCC+Boing-Semibold;
  font-style: normal;
  font-size: 30px;
  line-height: 40px;
  /* or 133% */  
  font-weight: 600;
  letter-spacing: 0.2px;    
}

.userEntryH4Boing {
  font-family: HILVCC+Boing-Semibold;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;  
}

.overlineIbmMedium {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* or 120% */
  letter-spacing: 0.5px;
}
.subtitle1IbmRegular {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.subtitle2IbmRegular {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */
  letter-spacing: 1px;
}

.subtitle2IbmSemiBold {
  @extend .subtitle2IbmRegular;
  font-weight: 600;  
}
