@use '../../typography';

.authorizeSlideContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-left: 16px;
  width: 360px;
  height: 149px;
  background: #FFFFFF;
  border: 2px solid #E63232;
  box-sizing: border-box;
  border-radius: 10px;
  &.success {
    border: 2px solid #68CF61;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .authorizeTitle {
    padding-top: 19px;
    @extend .TEXTH2blackmedium-emphasisleft;
  }
  .authorizeLabel {
    padding-top: 6px;
    @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
  }
  .authorizeIcon {
    position: absolute;
    width: 48px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0px;
    top: 0px;
    background: linear-gradient(297.25deg, #FC9B5C -12.12%, #FF6764 65.04%, #FF6464 95.66%);
    &.success {
      background: linear-gradient(311.79deg, #0CD656 -10.1%, #71DD6A 77.86%, #0CD656 125.77%);
    }
    border-radius: 0px 8px 0px 8px;
  }
  .authorizePasswordDetails {
    display: flex;
    padding-top: 6px;
    width: 100%;
    .authorizePasswordText {
      margin-right: 3px;
      width: 260px;
    }
  }
  .passwordTextBox {
    input {
      background-color: #FBFBFC;
    }
  }
  .acceptButton {
    width: 38px;
    height: 38px;
    border-radius: 21px;
    background-color: var(--WHITES-NORMAL-100);
    margin-left: 17px;
    margin-top: 24px;
  }
  .authorizeGpsDetails {
    display: flex;
    flex-direction: column;
    padding-top: 6px;
    @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
    .accessDenied {
      @extend .TEXTSUBTITLE-2redhigh-emphasisleft;
      padding-top: 6px;
    }
  }
}