@use '../../../../typography';

.gideBlogImageLeftPreviewContainer {
  position: relative;
    display: flex;
    max-width: 752px;
    width: 100%;
    height: 190px;
    border-radius: 5px;
    background-color: var(--WHITES-NORMAL-1000);
    @media (max-width: 520px) {
      height: 118px;
    }
    &.linkable:hover {
      cursor: pointer;
    }
    .image {      
      position: relative;
      img {
        width: 272px;
        max-width: 272px;
        height: 190px;
        max-height: 190px;
        border-radius: 3px;
        object-fit: cover;
        @media (max-width: 520px) {
          width: 167;
          max-width: 167px;
          height: 118px;
          max-height: 118px;
        }
      }
      .userLanguageFlag {
        position: absolute;
        top: 4px;
        right: 4px;
        border-radius: 50%; 
        width: 14px;
        height: 14px;
        
      }
    }
    .gidesLogoContainer {
      position: absolute;
      top: 2px;
      left: 2px;   
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 26px;
      height: 26px;
      background-color: var(--GREYS-NORMAL-300);
    }


    .details {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        @extend .TEXTH1blackhigh-emphasisleft;
        max-height: 80px;
        overflow: hidden;
        @media (max-width: 520px) {
          font-size: 14px;
          letter-spacing: 0.21px;
        }
      }
      .description {
        max-height: 72px;
        overflow: hidden;
        @extend .TEXTSUBTITLEblackmedium-emphasisleft;
      }
      .footerInfo {
        display: flex;
        align-items: center;
        flex-direction: row;
        .mobile {
          display: none;
          flex-direction: column;

        }
        @media (max-width: 520px) {
          flex-direction: column;
          align-items: flex-start;
          .desktop {
            display: none;
          }
          .mobile {
            display: flex;
          }
        }
      }
    }
  }
