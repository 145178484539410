.circleIconButtonContainer {
    position: relative;
    width: var(--width);
    height: var(--height);
    min-height: var(--height);
    min-width: var(--width);
    border-radius: 50%;
    // opacity: .8;
    // box-shadow: 2px 4px 6px 4px rgba(0, 0, 0, 0.03), 0 2px 2px 0 rgba(0, 0, 0, 0.02);
    svg,
    .circleIconButton {
        width: var(--iconWidth);
        height: var(--iconHeight);
        position: absolute;
        top: calc(50% - var(--iconHeightOffset));
        left: calc(50% - var(--iconWidthOffset));
    }
}

.circleIconButtonContainerHover {
    &:hover {
        cursor: pointer;
        opacity: 1;
    }
}
