.viewbarDimmer {
  display: block !important;
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .ui.inverted.dimmer {
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
}
