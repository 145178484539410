.slideEditorAudioCaptionContainer {
  display: flex;
  justify-content: space-between;
  height: 145px;
  padding-top: 20px;
  overflow-wrap: break-word;
  word-break: break-word;
  z-index: 2000;
  .slideEditorAudioCaption {
    display: flex;
    width: 230px;   
    padding-bottom: 50px;
    flex-direction: column;                
    justify-content: space-between;
    align-items: center;
    .slideEditorAudioCaptionControls {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      height: 92px;
      &.recording {
        justify-content: center;
      }
      .audioRecorderSlider {
        width: 80px;
        height: 34px;
        border-radius: 17px;
        box-shadow: 0 1px 9px 1px rgba(0, 0, 0, 0.09),
          0 0 4px 0 rgba(0, 0, 0, 0.06);
        background-color: #ffffff;
        position: relative;
        display: flex;
        align-items: center;
        .audioButton {
          position: absolute;
          left: -1px;
          top: -2px;
          &.notWaiting {
            right: 0px;                
            left: unset;
          }
        }
        .audioRecorderSliderLabel {
          @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
          position: absolute;
          right: 10px;
         
          &.notWaiting {
            left: 5px;
            right: unset;
          }
        }
      }
      .audioFileActions {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 92px;
        justify-content: space-evenly;
      }
      .exitAudio {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: var(--WHITES-NORMAL-100);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    .slideEditorAudioCaptionOptions {
      display: flex;          
      align-items: center;
      width: 205px;
      justify-content: space-between;          
      .e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check {
        background-color: #279df4;          
      }
      .e-checkbox-wrapper .e-frame, .e-css.e-checkbox-wrapper .e-frame {
        border-color: #279df4;
        border-radius: 3px;
        width: 16px;
        height: 16px;
      }          
    }
  }
  .hiddenAudioPlayer {
    display: none;
  }
  .recordingControl {
    &:hover {
      cursor: pointer;
    }
  }
  .audioFileDropzone {
    width: 71px;
    height: auto;
    border: none;
    div {
      height: auto !important;
      border-style: none !important;
    }
  }
}
