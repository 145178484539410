.fillFromColor * {
  fill: currentColor;
  stroke: var(--strokeColor);
}

.callout {    
  margin: 10px;
  &:focus { 
      outline: none;
  }
}
