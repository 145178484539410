.viewSettingsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 185px);
  width: 100%;
  max-width: 100vw;
  // padding: 0 5px 0 5px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  .sliderTicks {
    height: 3px;
    position: absolute;
    margin-top: 11px;
    background: repeating-linear-gradient(to right, transparent, transparent 17px, #dedede 10px, #dedede 20px);
  }
  .actionTab {
    height: 42px;
  }
  .refreshGideContainer {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--WHITE-BACKGROUND-01);
    cursor: pointer;
    .items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .refreshLabel {
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        /* identical to box height, or 150% */

        letter-spacing: 0.15px;

        /* link/100 */

        color: #32B3E0;
      }
    }
  }
}
.viewSettingCheckbox {
  @extend .TEXTSUBTITLEblackmedium-emphasisleft;
  display: flex;
  background-color: #fafafa;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  &.theme {
    width: 165px;
  }
  .viewSettingCheckboxDescription {
    display: flex;
    margin-left: 5px;
    align-items: center;
    width: 100%;
  }
}
.viewSettingImage {
  padding-right: 10px;
}
.viewSettingsHeader {
  @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
  margin-top: 24px;
  margin-bottom: 6px;
  margin-left: 12px;
}
.viewSettingFilter {
  @extend .TEXTSUBTITLEblackmedium-emphasisleft;
  display: flex;
  justify-content: space-between;
  height: 32px;
  background-color: #fafafa;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 12px;
  cursor: pointer;
}
.viewSettingTheme {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.viewSettingDetails {
  @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.viewModeSettings {
  width: 170px;
}
.viewModeAction {
  @extend .TEXTSUBTITLEblackmedium-emphasisleft;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}
.viewModeTitle {
  padding-left: 10px;
  margin-bottom: 11px;
}
.viewModeActionText {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.viewModeFontSize {
  @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
  @extend %inputRange;
}
.fontSlider {
  display: flex;
  cursor: pointer;
}
.actionExpander {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 36px 3px 44px;
  // align-items: center;
  // justify-content: space-evenly;
  // margin-top: 5px;
}
.expandAllAction {
  @extend .TEXTSUBTITLEblackmedium-emphasisleft;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 160px;
  height: 42px;
  border-radius: 3px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.01), 0 3px 16px 3px rgba(0, 0, 0, 0.01), 0 1px 2px 0 rgba(0, 0, 0, 0);
  background-color: var(--WHITES-NORMAL-1000);
  cursor: pointer;
}
.actionIcon {
  box-shadow: none;
}
