/*     Generated by Zeplin
    Copyright (C) 2019 - present Zeplin

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.
 */

@font-face {
  font-family: HILVCC+Boing;
  src: url('../assets/fonts/Boing-Medium.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: IBMPlexSans;
  src: url('../assets/fonts/IBMPlexSans-Regular.woff2') format("woff2");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: IBMPlexSans;
  src: url('../assets/fonts/IBMPlexSans-SemiBold.woff2') format("woff2");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}


.TEXTTIblackboldhigh-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  text-align: center;
  color: var(--GREYS-NORMAL-700);
}

.TEXTTIblackboldmedium-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  text-align: right;
  color: var(--GREYS-NORMAL-500);
}

.TEXTTIwhiteboldhigh-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTTIwhiteboldhigh-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTTIblackboldmedium-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.34px;
  color: var(--GREYS-NORMAL-500);
}

.TEXTTIwhiteboldlow-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  text-align: center;
  color: var(--WHITES-NORMAL-400);
}

.TEXTTIwhiteboldlow-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: var(--WHITES-NORMAL-400);
}

.TEXTTIwhiteboldlow-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  text-align: right;
  color: var(--WHITES-NORMAL-400);
}

.TEXTTIwhiteboldmedium-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

.TEXTTIwhiteboldmedium-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.7);
}

.TEXTTIblackboldmedium-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  text-align: center;
  color: var(--GREYS-NORMAL-500);
}

.TEXTTIwhiteboldmedium-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  text-align: right;
  color: rgba(255, 255, 255, 0.7);
}

.TEXTTIblackboldlow-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  text-align: right;
  color: #cccccc;
}

.TEXTTIblackboldhigh-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  text-align: right;
  color: var(--GREYS-NORMAL-700);
}

.TEXTTIblackboldlow-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: #cccccc;
}

.TEXTTIblackboldlow-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  text-align: center;
  color: #cccccc;
}

.TEXTTIwhiteboldhigh-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  text-align: right;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTTIblackboldhigh-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: var(--GREYS-NORMAL-700);
}

.TEXTH1blackhigh-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  text-align: right;
  color: var(--GREYS-NORMAL-700);
}

.TEXTH1blackmedium-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  text-align: right;
  color: rgba(0, 0, 0, 0.55);
}

.TEXTH1whitehigh-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTH1blackhigh-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  color: var(--GREYS-NORMAL-700);
}

.TEXTH1blackmedium-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  color: rgba(0, 0, 0, 0.55);
}

.TEXTH1whitelow-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  text-align: center;
  color: var(--WHITES-NORMAL-400);
}

.TEXTH1whitelow-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  color: var(--WHITES-NORMAL-400);
}

.TEXTH1whitelow-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  text-align: right;
  color: var(--WHITES-NORMAL-400);
}

.TEXTH1whitemedium-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  text-align: center;
  color: var(--WHITES-NORMAL-700);
}

.TEXTH1whitemedium-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  color: var(--WHITES-NORMAL-700);
}

.TEXTH1blackmedium-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.55);
}

.TEXTH1whitemedium-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  text-align: right;
  color: var(--WHITES-NORMAL-700);
}

.TEXTH1blacklow-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  text-align: right;
  color: #cccccc;
}

.TEXTH1blackhigh-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  text-align: center;
  color: var(--GREYS-NORMAL-700);
}

.TEXTH1blacklow-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  color: #cccccc;
}

.TEXTH1blacklow-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  text-align: center;
  color: #cccccc;
}

.TEXTH1whitehigh-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  text-align: right;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTH1whitehigh-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.21px;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTH2blackhigh-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  text-align: right;
  color: var(--GREYS-NORMAL-700);
}

.TEXTH2blackmedium-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  color: rgba(0, 0, 0, 0.55);
}

.TEXTH2blackmedium-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  text-align: right;
  color: rgba(0, 0, 0, 0.55);
}

.TEXTH2blackhigh-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  text-align: center;
  color: var(--GREYS-NORMAL-700);
}

.TEXTH2blackmedium-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  text-align: center;
  color: rgba(0, 0, 0, 0.55);
}

.TEXTH2whitehigh-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  text-align: right;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTH2whitelow-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  text-align: center;
  color: var(--WHITES-NORMAL-400);
}

.TEXTH2whitelow-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  color: var(--WHITES-NORMAL-400);
}

.TEXTH2whitelow-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  text-align: right;
  color: var(--WHITES-NORMAL-400);
}

.TEXTH2whitemedium-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  text-align: center;
  color: var(--WHITES-NORMAL-700);
}

.TEXTH2blacklow-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  text-align: right;
  color: #cccccc;
}

.TEXTH2whitemedium-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  color: var(--WHITES-NORMAL-700);
}

.TEXTH2blacklow-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  color: #cccccc;
}

.TEXTH2whitemedium-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  text-align: right;
  color: var(--WHITES-NORMAL-700);
}

.TEXTH2blacklow-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  text-align: center;
  color: #cccccc;
}

.TEXTH2blackhigh-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  color: var(--GREYS-NORMAL-700);
}

.TEXTH2whitehigh-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTH2whitehigh-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.18px;
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTH3blackhigh-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  color: var(--GREYS-NORMAL-700);
}

.TEXTH3blackmedium-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: right;
  color: rgba(0, 0, 0, 0.55);
}

.TEXTH3whitehigh-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTH3whitehigh-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTH3blackmedium-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.55);
}

.TEXTH3whitelow-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: center;
  color: var(--WHITES-NORMAL-400);
}

.TEXTH3whitelow-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  color: var(--WHITES-NORMAL-400);
}

.TEXTH3whitelow-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: right;
  color: var(--WHITES-NORMAL-400);
}

.TEXTH3whitemedium-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: center;
  color: var(--WHITES-NORMAL-700);
}

.TEXTH3whitemedium-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  color: var(--WHITES-NORMAL-700);
}

.TEXTH3blackmedium-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: center;
  color: rgba(0, 0, 0, 0.55);
}

.TEXTH3whitemedium-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: right;
  color: var(--WHITES-NORMAL-700);
}

.TEXTH3blacklow-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: right;
  color: #cccccc;
}

.TEXTH3blacklow-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: center;
  color: #cccccc;
}

.TEXTH3blacklow-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  color: #cccccc;
}

.TEXTH3blackhigh-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: center;
  color: var(--GREYS-NORMAL-700);
}

.TEXTH3whitehigh-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: right;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTH3blackhigh-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.15px;
  text-align: right;
  color: var(--GREYS-NORMAL-700);
}

.TEXTH4blackhigh-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: right;
  color: var(--GREYS-NORMAL-700);
}

.TEXTH4blackmedium-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: center;
  color: rgba(0, 0, 0, 0.55);
}

.TEXTH4blackmedium-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  color: rgba(0, 0, 0, 0.55);
}

.TEXTH4blackmedium-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: right;
  color: rgba(0, 0, 0, 0.55);
}

.TEXTH4blacklow-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: right;
  color: #cccccc;
}

.TEXTH4whitehigh-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTH4whitehigh-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: right;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTH4blackhigh-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  color: var(--GREYS-NORMAL-700);
}

.TEXTH4whitelow-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  color: var(--WHITES-NORMAL-400);
}

.TEXTH4whitelow-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: right;
  color: var(--WHITES-NORMAL-400);
}

.TEXTH4blacklow-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  color: #cccccc;
}

.TEXTH4whitemedium-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: center;
  color: var(--WHITES-NORMAL-700);
}

.TEXTH4blacklow-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: center;
  color: #cccccc;
}

.TEXTH4whitemedium-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  color: var(--WHITES-NORMAL-700);
}

.TEXTH4whitehigh-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTH4whitemedium-emphasisright {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: right;
  color: var(--WHITES-NORMAL-700);
}

.TEXTH4blackhigh-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: center;
  color: var(--GREYS-NORMAL-700);
}

.TEXTH4whitelow-emphasiscenter {
  font-family: HILVCC+Boing;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: center;
  color: var(--WHITES-NORMAL-400);
}

.TEXTBODYblackregularhigh-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.75);
}

.TEXTBODYblackregularmedium-emphasisright {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: right;
  color: rgba(0, 0, 0, 0.55);
}

.TEXTBODYwhiteregularhigh-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTBODYwhiteregularhigh-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTBODYblackregularmedium-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  color: rgba(0, 0, 0, 0.55);
}

.TEXTBODYwhiteregularlow-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: center;
  color: var(--WHITES-NORMAL-400);
}

.TEXTBODYwhiteregularlow-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  color: var(--WHITES-NORMAL-400);
}

.TEXTBODYwhiteregularlow-emphasisright {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: right;
  color: var(--WHITES-NORMAL-400);
}

.TEXTBODYwhiteregularmedium-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: center;
  color: var(--WHITES-NORMAL-700);
}

.TEXTBODYwhiteregularmedium-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  color: var(--WHITES-NORMAL-700);
}

.TEXTBODYblackregularmedium-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: center;
  color: rgba(0, 0, 0, 0.55);
}

.TEXTBODYwhiteregularmedium-emphasisright {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: right;
  color: var(--WHITES-NORMAL-700);
}

.TEXTBODYblackregularlow-emphasisright {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: right;
  color: #cccccc;
}

.TEXTBODYblackregularhigh-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.7);
}

.TEXTBODYblackregularlow-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  color: #cccccc;
}

.TEXTBODYblackregularlow-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: center;
  color: #cccccc;
}

.TEXTBODYwhiteregularhigh-emphasisright {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.28px;
  text-align: right;
  color: rgba(255, 255, 255, 0.95);
}

.TEXTBODYblackregularhigh-emphasisright {
  font-family: IBMPlexSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: rgba(0, 0, 0, 0.75);
}

.TEXTSUBTITLEblackhigh-emphasisright {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: right;
  color: rgba(0, 0, 0, 0.65);
}

.TEXTSUBTITLEgreencenter {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  text-align: center;
  color: #0cc474;
}

.TEXTSUBTITLEgreenleft {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #0cc474;
}

.TEXTSUBTITLEgreenright {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  text-align: right;
  color: #0cc474;
}

.TEXTSUBTITLEblueright {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: right;
  color: #32b3e0;
}

.TEXTSUBTITLEorangeleft {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  color: #fab317;
}

.TEXTSUBTITLEorangeright {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: right;
  color: #fab317;
}

.TEXTSUBTITLEredcenter {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: center;
  color: #e63232;
}

.TEXTSUBTITLEredleft {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  color: #e63232;
}

.TEXTSUBTITLEredright {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: right;
  color: #e63232;
}

.TEXTSUBTITLEblueleft {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  color: #32b3e0;
}

.TEXTSUBTITLEblackhigh-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}

.TEXTSUBTITLEbluecenter {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: center;
  color: #32b3e0;
}

.TEXTSUBTITLEwhitehigh-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.9);
}

.TEXTSUBTITLEblackmedium-emphasisright {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: right;
  color: rgba(0, 0, 0, 0.45);
}

.TEXTSUBTITLEwhitehigh-emphasisright {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: right;
  color: rgba(255, 255, 255, 0.9);
}

.TEXTSUBTITLEblackmedium-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.45);
}

.TEXTSUBTITLEwhitelow-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: center;
  color: var(--WHITES-NORMAL-400);
}

.TEXTSUBTITLEblackhigh-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.7);
}

.TEXTSUBTITLEwhitelow-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  color: var(--WHITES-NORMAL-400);
}

.TEXTSUBTITLEblacklow-emphasisright {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: right;
  color: #cccccc;
}

.TEXTSUBTITLEwhitelow-emphasisright {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: right;
  color: var(--WHITES-NORMAL-400);
}

.TEXTSUBTITLEblacklow-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  color: #cccccc;
}

.TEXTSUBTITLEwhitemedium-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: center;
  color: var(--WHITES-NORMAL-700);
}

.TEXTSUBTITLEblacklow-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: center;
  color: #cccccc;
}

.TEXTSUBTITLEwhitemedium-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  color: var(--WHITES-NORMAL-700);
}

.TEXTSUBTITLEorangecenter {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: center;
  color: #fab317;
}

.TEXTSUBTITLEwhitemedium-emphasisright {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: right;
  color: var(--WHITES-NORMAL-700);
}

.TEXTSUBTITLEblackmedium-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
}

.TEXTSUBTITLEwhitehigh-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
}

.TEXTH5blackhigh-emphasisleft {
  font-family: HILVCC+Boing;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.22px;
  color: var(--GREYS-NORMAL-700);
}

.TEXTSUBTITLE-2blueright {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: right;
  color: #32b3e0;
}

.TEXTSUBTITLE-2greenleft {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  color: #0cc474;
}

.TEXTSUBTITLE-2greenright {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: center;
  color: #0cc474;
}

.TEXTSUBTITLE-2blueleft {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  color: #32b3e0;
}

.TEXTSUBTITLE-2orangecenter {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: center;
  color: #f7b500;
}

.TEXTSUBTITLE-2orangeleft {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  color: #f7b500;
}

.TEXTSUBTITLE-2orangeright {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: right;
  color: #f7b500;
}

.TEXTSUBTITLE-2redhigh-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  color: #e63232;
}

.TEXTSUBTITLE-2redhigh-emphasisright {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: right;
  color: #e63232;
}

.TEXTSUBTITLE-2bluecenter {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: center;
  color: #32b3e0;
}

.TEXTSUBTITLE-2redright {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: center;
  color: #e63232;
}

.TEXTSUBTITLE-2blackmedium-emphasisright {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: right;
  color: rgba(0, 0, 0, 0.45);
}

.TEXTSUBTITLE-2whitehigh-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
}

.TEXTSUBTITLE-2blackmedium-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.48px;
  color: rgba(0, 0, 0, 0.45);
}

.TEXTSUBTITLE-2whitehigh-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  color: rgba(255, 255, 255, 0.9);
}

.TEXTSUBTITLE-2blackmedium-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
}

.TEXTSUBTITLE-2whitehigh-emphasisright {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: right;
  color: rgba(255, 255, 255, 0.9);
}

.TEXTSUBTITLE-2blacklow-emphasisright {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: right;
  color: #cccccc;
}

.TEXTSUBTITLE-2whitelow-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: center;
  color: var(--WHITES-NORMAL-400);
}

.TEXTSUBTITLE-2blacklow-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  color: #cccccc;
}

.TEXTSUBTITLE-2whitelow-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  color: var(--WHITES-NORMAL-400);
}

.TEXTSUBTITLE-2blacklow-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: center;
  color: #cccccc;
}

.TEXTSUBTITLE-2whitelow-emphasisright {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: right;
  color: var(--WHITES-NORMAL-400);
}

.TEXTSUBTITLE-2blackhigh-emphasisright {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: right;
  color: rgba(0, 0, 0, 0.65);
}

.TEXTSUBTITLE-2whitemedium-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: center;
  color: var(--WHITES-NORMAL-700);
}

.TEXTSUBTITLE-2blackhigh-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  color: rgba(0, 0, 0, 0.65);
}

.TEXTSUBTITLE-2whitemedium-emphasisleft {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  color: var(--WHITES-NORMAL-700);
}

.TEXTSUBTITLE-2blackhigh-emphasiscenter {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}

.TEXTSUBTITLE-2whitemedium-emphasisright {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: right;
  color: var(--WHITES-NORMAL-700);
}

.TEXTSUBTITLE-2greenright {
  font-family: IBMPlexSans;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: right;
  color: #0cc474;
}
