.imageGallerySlide {
  align-items: center;
  @extend %flexColumnFull;
  .imageGalleryTitle {
    @extend .TEXTH4blackhigh-emphasisleft;
    width: 100%;
  }
  .imageGalleryCaption {
    @extend .TEXTBODYblackregularmedium-emphasisleft;
    max-height: 70px;
    overflow: hidden;
  }
  .imageGalleryFileCaptionContainer {
    max-width: $MAXSLIDEWIDTH;
    // height: 25px;
    border-radius: 2px;
    // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    display: flex;
    flex-wrap: no-wrap;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    padding: 3px 9px 3px 9px;
    overflow-wrap: break-word;
    word-break: break-word;
  }
}
