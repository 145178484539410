.gideElementSearchContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  .searchTypes {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .searchElements {
    display: flex;
    align-items: center;
  }
  .searchType {
    margin-left: 10px;
    margin-right: 10px;
  }
  .searchResults {
    height: 270px;
    max-height: 270px;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .searchResultItem {
      min-height: 40px;
      display: flex;
      align-items: center;
      padding: 5px;
      font-family: Aileron-SemiBold;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.75);
      border-bottom: solid 1px rgba(0, 0, 0, 0.12);
      .deleteShortcutIcon {
          width: 45px;
          height: 6px;
      }
      .shortcutTitle {
          width: 230px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
      }
      .shortcutImage {
          &.article {
              width: 34px;
              height: 24px;
              border-radius: 3px;
              object-fit: cover;
              margin: 0 6px 0 16px;
          }
          &.user {
              border: 1px solid white;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
              margin: 0 8px 0 18px;
          }
          &.channel {
              width: 34px;
              height: 24px;
              border-radius: 3px;
              object-fit: cover;
              margin: 0 6px 0 16px;
          }
      }
  }
  }
}
