@use '../../../../assets/gides-typography.module';
.pickerContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--WHITES-NORMAL-1000);

  .title {
    @extend .userEntryH4Boing;
    margin: 16px 16px 16px 34px;
    color: var(--COLOR-SECONDARY-600);
  }
  .pickerItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .pickerItemAligner {    
    height: 0px;
    width: 117px;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;;
  }
  .circularPickerItem {
    margin: 11px 0 11px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .circularPickerItemFormatLabel {
      @extend .overlineIbmMedium;
      color: var(--COLOR-SECONDARY-400);
    }
  }
}
