.additionAdderContainer {
  height: 124px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}
  .actionTab {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    &:hover {
      cursor: pointer;
    }
    .tabText {
      @extend .TEXTSUBTITLEblackmedium-emphasiscenter;
      width: 125px;
      padding: 11px;
      border-bottom-style: solid;
      border-bottom-width: 2px;
      border-bottom-color: var(--COLOR-SECONDARY-200);
      &.selectedTab {
        @extend .TEXTSUBTITLEblackhigh-emphasiscenter;
        border-bottom-color: var(--COLOR-PRIMARY-600);
      }
    }
  }
  .action {
    height: 63px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .actionButton {
    @extend .TEXTSUBTITLE-2blackmedium-emphasiscenter;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 174px;
    height: 44px;
    border-radius: 3px;
    background-color: var(--COLOR-SECONDARY-100);
    &:hover {
      cursor: pointer;
    }
  }
  .actionButton:last-child {
    margin-left: 4px;
  }
