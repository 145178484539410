.e-richtexteditor 
.e-rte-content
.e-content table td,
.e-richtexteditor
.e-rte-content .e-content table th {
    min-width: 0px;;
}
.e-content {
    // .div {
    //     max-width: 90% !important;
    // }
    table[style] {
        max-width: 100% !important;
        width: 100% !important;        
    }
    table {
        max-width: 100% !important;
        tr {
            width: auto !important;
            td {
                width: auto !important;
                min-width: 0px;
            }
            td[style] {
                width: auto !important;
                min-width: 0px;
            }
        }
    }
    div, img, a, p, tr, td {
        max-width: 100%;
    }
    a {
        overflow-wrap: break-word;
        word-break: break-word;
    }
    p {
      color: rgba(0, 0, 0, 0.7) !important;
    }
}
