.imageViewOuterContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .imageViewImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    // max-width: $MAXSLIDEWIDTH; // TODO: Handle edge to edge and %
    a {
      width: 100%;
    }
    a .sourceImage,
    .sourceImage {
      width: 100%;
      &.useNativeResolution {
        width: auto !important;
        min-width: auto !important;
        object-fit: scale-down;
        max-width: 100% !important;
      }
    }
    .imageViewCaptionContainer {
      border-radius: 2px;
      // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
      background-color: #ffffff;
      display: flex;
      flex-wrap: no-wrap;
      align-items: flex-start;
      width: 100%;
      flex-direction: column;
      padding: 3px 9px 9px 9px;
      .customAudioPlayer {
        width: 100%;
      }
      overflow-wrap: break-word;
      word-break: break-word;
    }
  }
  &.hasEnsuingItem {
    margin-bottom: 4px;
  }
  .slideReferenceContainer {
    width: 100%;
  }
  &.containImage {
    .imageViewImageContainer {
      .sourceImage {
        max-height: calc(100vh - 235px);
        object-fit: contain;
        max-width: 100%;
      }
    }
  }
}
.slideEditorModalContent.EDGE .imageViewOuterContainer .imageViewImageContainer {
  max-width: 100%;
}
.slideEditorModalContent.HALF .imageViewOuterContainer .imageViewImageContainer {
  max-width: 100%;
}
@media (min-width: #{$MAXSLIDEWIDTH}) {
  .website {
    .card.EDGE,
    .card.HALF {
      .imageViewImageContainer {
        margin-left: 0px;
        max-width: 100%;
      }
    }
  }
  // SLIDE-WIDTH Percentages in slide width settings (Normal xxx% and HALF xxx%) need to work when viewing
  // the article, not only in webside mode like EDGE.
  .article-page {
    .SCROLL,
    .SLIDE {
      .card.HALF {
        .imageViewImageContainer {
          margin-left: 0px;
          max-width: 100%;
        }
      }
    }
  }
}
@media only screen and (max-width: 520px) {
  .imageViewOuterContainer {
    &.containImage {
      .imageViewImageContainer {
        .sourceImage {
          max-height: calc(100vh - 182px);
        }
      }
    }
  }
}
