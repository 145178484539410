.slideEditGideHeaderContainer {
  @extend .TEXTSUBTITLEblackmedium-emphasisright;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  width: 100%;
  @media (max-width: 520px) {
    height: 48px;
  }
  .slideSelectionTransferType {
    display: flex;
    align-items: center;
    width: 168px;
    justify-content: space-between;
  }
  .multiSelectionActions {
    display: flex;
    .slideSelectionActionButton {
      background-color: var(--WHITES-NORMAL-1000);
      color: var(--COLOR-SECONDARY-500);
      height: 42px;
      &.selected {
        background-color: var(--COLOR-SECONDARY-500);
        color: var(--WHITES-NORMAL-1000);
      }
    }
  }
  .exitButton {
    margin-left: 12px;
    &:hover {
      cursor: pointer;
    }
  }
  .actionType {
    display: flex;
    align-items: center;
    margin-right: 9px;
  }
}
