.viewbarContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  // position: fixed;
  bottom: 0px; // 12px;
  z-index: 3;  
  width: 100vw;
  // max-height: calc(100vh - 200px);
  pointer-events: none;
  @media (max-width: 520px) {
    bottom: 2px;
  }
  &.inputBar {
    bottom: 68px;
    @media (max-width: 520px) {
      bottom: 56px;
    }
  }
}

.viewbarOpenModalContainer {
  pointer-events: all;
  width: 100%;
  max-width: 752px;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  .viewbarOpenModalButton {
    &:hover {
      cursor: pointer;
    }
    width: 30px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px var(--COLOR-PRIMARY-300);
    border-radius: 3px;
    background: var(--WHITES-NORMAL-1000);
    margin-left: 48px;
    margin-bottom: 8px;

  }
}

.viewbarActions {
  display: flex;
  flex: 1;
  &.hide {
    display: none;
  }
  width: 100%;
  max-width: 752px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--WHITES-NORMAL-1000);  
  pointer-events: all;
  .rootViewbar {
    display: flex;
    width: 100%;
    height: 60px;
    border-radius: 4px;
    justify-content: space-between;
    z-index: 1;
    border-top: solid var(--COLOR-SECONDARY-200) 1px;
    padding-top: 7px;
    background-color: var(--WHITES-NORMAL-1000);
    .viewBarItem {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      &:hover {
        cursor: pointer;
      }
      .title {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 1px;
        color: var(--COLOR-SECONDARY-300);
        &.selected {
          color: var(--COLOR-SECONDARY-500);
        }
      }
    }
  }
  .viewbarAction {
    &:hover {
      cursor: pointer;
    }
  }
  .viewbarSelectedAction {
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    width: 100%;
  }
  .actionHeader {
    @extend .TEXTSUBTITLEblackmedium-emphasisright;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    width: 100%;
  }
  .actionHeaderSeparator {
    // width: 355px;
    width: 100%;
    height: 1px;
    background-color: var(--COLOR-SECONDARY-200);
  }
  .exitAction {
    margin-left: 12px;
    &:hover {
      cursor: pointer;
    }
  }
  .actionType {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-right: 10px;
  }
  .actionIcon {
    margin-left: 10px;
    height: 30px;
    width: 30px;
    min-width: 30px;
    min-height: 30px;
    &:hover {
      cursor: unset;
    }
  }
}

