@use '../../../typography';

.labeledTextInput {
  // background-color: var(--GREYS-NORMAL-1000);
  caret-color: auto;
  &.light {
    .label {
      @extend .TEXTH3blackhigh-emphasisleft;
    }
    .sublabel {
      @extend .TEXTSUBTITLEblackmedium-emphasisleft;
    }
    textarea {      
      @extend .TEXTBODYblackregularhigh-emphasisleft;
    }
    .otherOptions {
      @extend .TEXTSUBTITLEblackmedium-emphasisleft;
    }  
    .uploadLabel {
      @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
    }
  }

  .label {
    @extend .TEXTH3whitehigh-emphasisleft;
    margin-bottom: 8px;
    margin-left: 16px;    
  }

  .sublabel {
    @extend .TEXTSUBTITLEwhitemedium-emphasisleft;
    margin-bottom: 24px;
    margin-left: 16px;
  }

  
  .textInput {
    min-width: 100px;
    max-width: 752px;
    min-height: 42px;
    object-fit: contain;
    border-radius: 21px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.04), 0 3px 16px 3px rgba(0, 0, 0, 0.02), 0 1px 2px 0 rgba(0, 0, 0, 0.01);
    background-color: var(--WHITES-NORMAL-100);
    margin: 5px 0;
    margin-left: 0px;
    display: flex;    
    flex: 1 1;
    align-items: flex-start;
  }

  textarea {
    // @extend .TEXTBODYwhiteregularlow-emphasisleft;
    @extend .TEXTBODYwhiteregularhigh-emphasisleft;
    background-color: transparent;
    margin-left: 14px;
    margin-right: 16px;
    height: 28px;
    min-height: 28px;
    max-height: 74px;
    border: none;
    width: 100%;
    outline: none;
    box-shadow: none;
    resize: none;
    align-self: center;
  }

  .clearButton {
    margin-top: 2px;
    margin-right: 9px;
    box-shadow: none;
  }

  .acceptButton {
    width: 38px;
    height: 38px;
    border-radius: 21px;
    background-color: var(--WHITES-NORMAL-100);
    margin-bottom: 7px;
    margin-left: 17px;
  }

  .acceptButtonIcon {
    color: var(--WHITES-NORMAL-650);
  }

  .row {
    display: flex;
    align-items: flex-end;
  }
}

.actionContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;    
}
.rightActionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}    
.rightActionsInnerContainer {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
// padding-right: 22px;
  .otherOptions {
    margin-top: -24px;
    margin-bottom: 22px;
  }
}
.rightActions {
  display: flex;      
  justify-content: center;  
  justify-items: center;
  margin-top: 11px;
  margin-bottom: 22px;
  .labeledButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .uploadLabel {
      @extend .TEXTSUBTITLE-2whitehigh-emphasiscenter;
      margin-top: 5px;
    }
  }      
}
.otherOptions {      
  @extend .TEXTSUBTITLEwhitehigh-emphasiscenter;
  // display: flex;      
  // justify-content: center;
  // padding-right: 22px;
}
.mobileActionContainer {
  display: none;
}

@media (max-width: 520px) {
  .rightActionsInnerContainer {
    display: none !important;
  }
  .mobileActionContainer {
    display: block !important;
  }
  .otherOptions {
    margin-top: -14px;
    margin-bottom: 10px;
  }
}
