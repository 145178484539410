.multiImageContainer {
  position: relative;
  width: 100%;
  &.desktop {
    display: none;
    @media (min-width: 768px) {
      display: flex;
    }
  }
  &.tablet {
    display: none;
    @media (min-width: 521px) and (max-width: 767px) {
      display: flex;
    }
  }
  &.mobile {
    display: none;
    @media (max-width: 520px) {
      display: flex;
    }
  }
  .positionedComponent {    
    position: absolute;
    top: var(--top);
    bottom: var(--bottom);
    right: var(--right);
    left: var(--left);
    width: var(--width);
    align-items: var(--alignItems);
    @media (max-width: 520px) {
      top: var(--mobileTop);
      bottom: var(--mobileBottom);
      right: var(--mobileRight);
      left: var(--mobileLeft);
      width: var(--width);
      align-items: var(--alignItems);
    }
  }
}
