.website {
  width: 100%;
  .card-footer {
      display: none;
  }

  .tabMenu {
      padding: 10px;
      display: block !important;
      &.RIGHT {
          .item {
              float: right !important;
          }
      }
      .item {
          display: none;
          cursor: pointer;
          opacity: .85;
          &:hover {
              opacity: 1;
              margin-bottom: 5px;
          }
      }
  }
  .COLUMN {
      display: none;
  }
  .EDGE {
      max-width: auto;
      width: 100% !important;
  }
}
#website-menu {
  .MuiList-root.MuiMenu-list.MuiList-padding {
    background: var(--menuBackgroundColor) !important;    
    color: var(--menuFontColor) !important;
    border: solid 1px white;
  }
}
.website {
  .slideView {
      .card.EDGE {
          max-width: 100% !important;
          justify-content: center;
          display: flex;
      }
  }
}

.website .logo {
  width: 100px;
  cursor: pointer;
  &.CENTER {
      margin: 0 auto;
  }
  &.RIGHT {
      float: right;
  }
}

.website {
  .row {
      height: inherit;
      background: transparent;
  }
}

// Over 800 px when slide width is set to HALF
@media only screen and (min-width: $MAXSLIDEWIDTH) {
  .website {
     .slideView {
         .card.HALF {
             max-width: calc(#{$MAXSLIDEWIDTH} + ((100vw - #{$MAXSLIDEWIDTH}) / 2))  !important;
         }
     }
     .HALF {
         max-width: calc(#{$MAXSLIDEWIDTH} + ((100vw - #{$MAXSLIDEWIDTH}) / 2))  !important;
     }
  }
 //  .SCROLL, .SLIDE {
 //  .article-page {
 //     .slideView {
 //         .card.HALF {
 //             max-width: calc(#{$MAXSLIDEWIDTH} + ((100vw - #{$MAXSLIDEWIDTH}) / 2))  !important;
 //         }
 //     }
 //     .HALF {
 //         max-width: calc(#{$MAXSLIDEWIDTH} + ((100vw - #{$MAXSLIDEWIDTH}) / 2))  !important;
 //     }
 //  }
 // }
}
