.pickerModalContainer {
  display: flex;
  flex-direction: column;
  // border: solid 1px var(--COLOR-SECONDARY-200);
  border-radius: 0px 0px 10px 10px;
  background-color: white;
  max-width: 360px;
  width: 100%;
  .header {
    display: flex;
  }
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .horizontalLine {
    align-self: center;
    width: 328px;
    height: 1px;
    padding: 0 16px;
    border-radius: .5px;
    background-color: var(--COLOR-SECONDARY-100);
  }
  .actions {    
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 16px;
  }
}
