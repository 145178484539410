.linkViewContainer {
  width: 100%;
  // max-width: 752px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .slideReferenceContainer {
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  }
}
