@import '../vars';
.verticalLine {
  background-color: $verticalLine;
  height: 52px;
  width: 1px;
  @media (max-width: 520px) {
    height: 39px;
    &.double {
      margin-top: 5px;
      height: 43px;
    }
  }
}

.verticalSeparator {
  height: 16px;
  width: 2px;
  color: inherit;
}
