$color-red-100: #fc744d;
$color-green-100: #68cf61;
$color-blue-100: #82b9fe;
$color-yellow-100: #ffd959;

:root {
  --COLOR-PRIMARY-800: #f99012;
  --COLOR-PRIMARY-700: #faa114;
  --COLOR-PRIMARY-600: #ffb213;
  --COLOR-PRIMARY-500: #F99012;
  --COLOR-PRIMARY-400: #FAA114;
  --COLOR-PRIMARY-300: #FEB213;
  --COLOR-PRIMARY-200: #FAC11D;
  --COLOR-PRIMARY-100: #FBCA32;
  --COLOR-SECONDARY-600: #282828;
  --COLOR-SECONDARY-500: #737373;
  --COLOR-SECONDARY-400: #919191;
  --COLOR-SECONDARY-300: #BEBEBE;
  --COLOR-SECONDARY-200: #D6D6D6;
  --COLOR-SECONDARY-100: #F0F0F0; // This is same as 200 but added per lukes design
  --COLOR-RED-100: #{$color-red-100};
  --COLOR-RED-50: rgba(252, 116, 77, 0.6);
  --COLOR-RED-20: rgba(252, 116, 77, 0.3);
  --COLOR-GREEN-100: #{$color-green-100};
  --COLOR-GREEN-50: rgba(12, 214, 86, 0.6);
  --COLOR-GREEN-20: rgba(12, 214, 86, 0.3);
  --COLOR-BLUE-100: #{$color-blue-100};
  --COLOR-BLUE-50: rgba(130, 185, 254, 0.6);
  --COLOR-BLUE-20: rgba(130, 185, 254, 0.3);
  --COLOR-BLUE-10: rgba(130, 185, 254, 0.1);
  --COLOR-PURPLE-100: #ce93e4;
  --COLOR-PURPLE-50: rgba(206, 147, 228, 0.6);
  --COLOR-PURPLE-20: rgba(206, 147, 228, 0.3);
  --COLOR-YELLOW-100: #{$color-yellow-100};
  --COLOR-YELLOW-50: rgba(255, 217, 89, 0.6);
  --COLOR-YELLOW-20: rgba(255, 217, 89, 0.3);
  --GREYS-NORMAL-1000: #121212;
  --GREYS-NORMAL-900: rgba(0, 0, 0, 0.9);
  --GREYS-NORMAL-700: rgba(0, 0, 0, 0.7);
  --GREYS-NORMAL-600: rgba(0, 0, 0, 0.6);
  --GREYS-NORMAL-500: rgba(0, 0, 0, 0.5);
  --GREYS-NORMAL-400: rgba(0, 0, 0, 0.4);
  --GREYS-NORMAL-300: rgba(0, 0, 0, 0.3);
  --GREYS-NORMAL-200: rgba(0, 0, 0, 0.2);
  --GREYS-NORMAL-150: rgba(0, 0, 0, 0.15);
  --GREYS-NORMAL-100: rgba(0, 0, 0, 0.1);
  --GREYS-NORMAL-50: rgba(0, 0, 0, 0.05);
  --WHITES-NORMAL-1000: #ffffff;
  --WHITES-NORMAL-900: rgba(255, 255, 255, 0.9);
  --WHITES-NORMAL-800: rgba(255, 255, 255, 0.8);
  --WHITES-NORMAL-700: rgba(255, 255, 255, 0.7);
  --WHITES-NORMAL-650: rgba(255, 255, 255, 0.65);
  --WHITES-NORMAL-500: rgba(255, 255, 255, 0.5);
  --WHITES-NORMAL-400: rgba(255, 255, 255, 0.4);
  --WHITES-NORMAL-300: rgba(255, 255, 255, 0.3);
  --WHITES-NORMAL-150: rgba(255, 255, 255, 0.15);
  --WHITES-NORMAL-100: rgba(255, 255, 255, 0.1);
  --WHITES-NORMAL-50: rgba(255, 255, 255, 0.05);
  --WHITE-BACKGROUND-03: #e8e6e6;
  --WHITE-BACKGROUND-02: #f1f2f3;
  --WHITE-BACKGROUND-01: #fbfbfc;
  --COLOR-PRIMARY-700-HALF: rgb(250, 161, 20, 0.5);

  --LINK-100: #32B3E0;
  --LINK-20:  rgba(50, 179, 224, 0.2);
  --SUCCESS-100: #68cf61;



  .color-primary-700-svg {
    filter: brightness(0) saturate(100%) invert(68%) sepia(26%) saturate(3277%) hue-rotate(351deg) brightness(104%) contrast(96%);
  }
  .color-primary-600-svg {
    filter: brightness(0) saturate(100%) invert(78%) sepia(94%) saturate(2464%) hue-rotate(335deg) brightness(95%) contrast(106%);
  }
  .color-primary-500-svg {
    filter: brightness(0) saturate(100%) invert(81%) sepia(73%) saturate(2147%) hue-rotate(339deg) brightness(98%) contrast(99%);
  }
  
  .color-secondary-800-svg {
    filter: brightness(0) saturate(100%) invert(48%) sepia(1%) saturate(0%) hue-rotate(199deg) brightness(93%) contrast(96%);
  }
  .color-secondary-600-svg {
    filter: brightness(0) saturate(100%) invert(68%) sepia(7%) saturate(10%) hue-rotate(43deg) brightness(84%) contrast(88%);
  }
  .color-secondary-500-svg {
    filter: brightness(0) saturate(100%) invert(69%) sepia(13%) saturate(0%) hue-rotate(291deg) brightness(103%) contrast(91%);
  }

  .color-secondary-300-svg {
    filter: brightness(0) saturate(100%) invert(99%) sepia(7%) saturate(647%) hue-rotate(199deg) brightness(107%) contrast(68%);
  }
  .color-secondary-200-svg {
    filter: brightness(0) saturate(100%) invert(100%) sepia(35%) saturate(63%) hue-rotate(213deg) brightness(112%) contrast(88%);
  }
  .color-secondary-100-svg {
    filter: brightness(0) saturate(100%) invert(97%) sepia(0%) saturate(2426%) hue-rotate(121deg) brightness(122%) contrast(68%);
  }

  %color-blue-100-svg {
    filter: brightness(0) saturate(100%) invert(69%) sepia(12%) saturate(2442%) hue-rotate(186deg) brightness(103%) contrast(99%);
  }
  .color-blue-100-svg {
    @extend %color-blue-100-svg;
  }

  .color-purple-200-svg {
    filter: brightness(0) saturate(100%) invert(38%) sepia(55%) saturate(4596%) hue-rotate(252deg) brightness(96%) contrast(87%);;
  }
  .color-green-300-svg {
    filter: brightness(0) saturate(100%) invert(52%) sepia(97%) saturate(418%) hue-rotate(102deg) brightness(95%) contrast(91%);
  }
  .color-green-200-svg {
    filter: brightness(0) saturate(100%) invert(82%) sepia(98%) saturate(2197%) hue-rotate(74deg) brightness(99%) contrast(96%);
  }
  .color-tomato-svg {
    filter: brightness(0) saturate(100%) invert(23%) sepia(92%) saturate(2889%) hue-rotate(349deg) brightness(101%) contrast(80%);
  }
  .whites-normal-1000-svg {
    filter: brightness(0) saturate(100%) invert(99%) sepia(3%) saturate(0%) hue-rotate(95deg) brightness(115%) contrast(100%);
  }
  .whites-normal-900-svg {
    filter: brightness(0) saturate(100%) invert(99%) sepia(3%) saturate(0%) hue-rotate(95deg) brightness(115%) contrast(100%) opacity(.9);
  }
  %whites-normal-800-svg {
    filter: brightness(0) saturate(100%) invert(99%) sepia(3%) saturate(0%) hue-rotate(95deg) brightness(115%) contrast(100%) opacity(.8);
  }
  .whites-normal-800-svg {
    @extend %whites-normal-800-svg;
  }
  

  // Safe area insets for iphone (NOTE: NOT CURRENTLY USED)
  --safe-top : env(safe-area-inset-top);
  --safe-right : env(safe-area-inset-right);
  --safe-bottom : env(safe-area-inset-bottom);
  --safe-left : env(safe-area-inset-left);
}
