@use '../../typography';

.gidePreviewListContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: hidden;
  @media (max-width: 520px) {
    padding: 0;
  }
  @extend .TEXTH4blackmedium-emphasisleft;
  .gidePreviewList {
    display: flex;
    flex-direction: column;
    // padding: 0 5px 60px 5px;
    padding: 0 5px;
    @media (max-width: 520px) {
      // padding: 0 0 60px 0;
      padding: 0 0;
    }
    &.searchResults {
      .gidePreviewTitleContainer {
        display: none;
      }
    }
    .gidePreviewTitleContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      > div {
        margin-right: 6px;
        margin-left: 6px;
        @media (max-width: 520px) {
          margin-right: 3px;
          margin-left: 3px;
        }
        @media (max-width: 365px) {
          margin-right: 1px;
          margin-left: 1px;
        }
      }
      .gidePreviewTitle {
        min-width: 176px;
        height: 24px;
        margin-bottom: 8px;
      }
    }
    .gidePreviewGrid {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      > div {
        margin-right: 6px;
        margin-left: 6px;
        margin-bottom: 14px;
        @media (max-width: 520px) {
          margin-right: 4px;
          margin-left: 4px;
          margin-bottom: 10px;
        }
        @media (max-width: 375px) { // This is needed to keep homepage viewable @ 360px with 2 columns.
          margin-right: 3px;
          margin-left: 3px;
        }
      }
      > div:nth-child(odd) {
        @media (max-width: 365px) {
          margin-left: 0px !important;
        }
      }
      > div:nth-child(even) {
        @media (max-width: 365px) {
          margin-right: 0px !important;
        }
      }
    }
    .emptyGidePreview {
      width: 188px;
      height: 0px;
      max-height: 0px !important;
      @media (max-width: 520px) {
        width: 184px;
      }
      @media (max-width: 375px) {
        width: 182px;
      }
      @media (max-width: 365px) {
        width: 180px;
      }
    }
    .moreItemsHorizontalLine {
      background-color: var(--COLOR-SECONDARY-200);
      height: 4px;
      width: calc(100% - 120px);
    }
  }
}
