@use '../../../typography';

.tertiaryButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 105px;
  height: 40px;
  border-radius: 3px;
  background-color: var(--COLOR-SECONDARY-200);  
  cursor: pointer;
  &.selected {
    background-color: var(--COLOR-SECONDARY-500);
    cursor: inherit;
  }
  .buttonLabel {
    display: flex;
    align-items: center;
    @extend .TEXTSUBTITLE-2blackmedium-emphasisleft;
    &.selected {
      color: rgba(255, 255, 255, 0.9);
    }
  }
}
