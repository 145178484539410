@use '../../../../assets/gides-typography.module';

.userTileContainer {
  display: flex;
  flex: 1;
  height: 62px;
  align-items: center;
  margin-left: 7px;
  background-color: var(--WHITES-NORMAL-1000);

  .avatarContainer {
    display: flex;
    position: relative;
    .userLanguageFlag {
      position: absolute;
      bottom: 7px;
      right: 8px;
      border-radius: 50%; 
      width: 14px;
      height: 14px;
      border: solid 1px var(--WHITES-NORMAL-1000);
    }
  }
  .userDetails {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 18px;
    .userInfo {
      @extend .subtitle1IbmRegular;
      display: flex;
      align-items: center;
      margin-bottom: 3px;
    }
    .categoryAndCounts {
      margin-top: 3px;
      display: flex;
      align-items: center;
      @media (max-width: 520px) {
        font-size: 10px;
      }
      .category {       
        margin-right: 3px;
        color: var(--COLOR-PRIMARY-300);
      }
      .counts {
        display: flex;
        align-items: center;
      }
    }
  }
}
