@import '../../../assets/gides-typography.module';
.viewBarSearchOuterContainer {
  display: flex;
  width: 100%;
  max-width: 752px;
  margin-bottom:35px;
  align-items: center;
  .viewBarSearchContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;  
    height: 40px;
    width: 100%;
    max-width: 702px;
    border-radius: 6px;
    background-color: var(--COLOR-SECONDARY-100);
    &:hover {
      background-color: var(--COLOR-SECONDARY-200);
    }
    &:focus,
    &.hasText {
      background-color: var(--COLOR-SECONDARY-100);    
    }
    pointer-events: all;
    .viewBarSearchNav {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      cursor: pointer;
      &.hide {
        display: none;
      }
      .arrowLeft {
        margin-right: 11px;
      }
    }

    .viewBarRight {
      display: flex;
      margin-right: 14px;
      align-items: center;
    }
    .viewBarSearchInput {
      @extend .subtitle2IbmSemiBold;
      background: transparent !important;
      &.hasText {
        color: var(--COLOR-SECONDARY-500);
      }
      border: none;
      width: 100%;
      margin-left: 16px;
      margin-right: 5px;
      margin-top: 2px;
      height: 24px;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      resize: none;
      overflow: hidden;
    }
  }
  .viewBarSearchExitIcon {
    pointer-events: all;
    margin-left: 12px;
    cursor: pointer;
  }
}
